import { FC, ReactElement, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Spinner } from '../../../../shared/styles/Spinner';
import { IReportInfo } from './ReportInfo';
import { RoundedWrapper, PaddingWrapper, BorderWrapper as Border, ColumnWrapper } from '../../styles/Wrappers';
import { downloadCsv } from '../../../../requests/reports';
import constants from '../../../../shared/constants';
import { Arrow, ArrowWrapper } from './ReportInfo';
import { Insights, ReportMetadata } from '../../../../utils/interfaces';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { CircleDot } from '../../../../shared/styles/CircleDot';

const DetectionsWrapper = styled(PaddingWrapper)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
`;

const List = styled.ul`
    list-style-type: none;
    margin: 0;
`;
const LiElement = styled.li<{ active?: Boolean; visible?: Boolean }>`
    margin: 0;
    margin-bottom: 5px;
    line-height: 25px;
    cursor: ${(props) => (props.active ? 'pointer' : 'default')};
    color: ${(props) =>
        props.active ? (props.visible ? 'white' : 'rgba(255, 255, 255, 0.5)') : 'rgba(255, 255, 255, 0.3)'};
    img {
        margin-right: 15px;
        max-width: 12px;
    }
`;
const Secondary = styled(PaddingWrapper)<{ border?: Boolean }>`
    flex-direction: row;
    background: #002540;
    border-bottom: ${(props) => props.border && '1px solid rgba(255, 255, 255, 0.2)'};
    border-radius: ${(props) => !props.border && '0 0 5px 5px'};
    cursor: pointer;
    align-items: center;
    &:hover {
        box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
    }
    img {
        max-width: 16px;
        max-height: 16px;
        margin-left: 30px;
    }
`;

interface IMoreInfo extends IReportInfo {
    reportName: string;
    insights: Insights | undefined;
    lastItem: Boolean;
    metadata: ReportMetadata | undefined;
    nextLayer: Function;
    prevLayer: Function;
    chosenDate: string;
    currMap: undefined | mapboxgl.Map;
    visibleLayers: string[] | undefined;
    setVisibleLayers: (layers: string[] | undefined) => void;
}

interface ILegend {
    id?: string;
    text: string;
    tooltip?: string;
    active: Boolean;
    icon: string | ReactElement;
    visibleLayers: string[] | undefined;
    setVisibleLayers: (layers: string[] | undefined) => void;
    currMap: undefined | mapboxgl.Map;
}

export const MoreInfo: FC<IMoreInfo> = ({
    index,
    lastItem,
    availableDates,
    reportName,
    metadata,
    prevLayer,
    nextLayer,
    chosenDate,
    insights,
    currMap,
    visibleLayers,
    setVisibleLayers,
    setProcessingStatus,
    detectionConfidence,
}) => {
    const [downloadingCsv, setDownloadingCsv] = useState<Boolean>(false);
    const [visibleMetaData, setVisibleMeta] = useState<Boolean>(false);
    const [shipDetected, setShipDetected] = useState<number>(0);
    const [darkShipsNumber, setDarkShipsNumber] = useState<number>(0);
    const [unCorrelatedAisNumber, setUncorrelatedAisNumber] = useState<number>(0);
    const [areDetectionsCorrelated, setAreDetectionsCorrelated] = useState<Boolean>(false);

    // useEffect for setting number of detected ships for map
    useEffect(() => {
        if (insights) {
            if (insights.undetected_ais) {
                setUncorrelatedAisNumber(insights.undetected_ais.features.length);
            }
            if (insights.matched_vessels) {
                setAreDetectionsCorrelated(true);
                setShipDetected(insights.matched_vessels.features.length);
            }
            if (insights.dark_vessels) {
                setDarkShipsNumber(
                    insights.dark_vessels.features.filter(
                        (feature) => feature.properties?.object_class_score >= detectionConfidence / 100
                    ).length
                );
            }
        }
    }, [insights, index, detectionConfidence]);
    let legendColor = constants.colors.markers;
    const legends: ILegend[] = [
        {
            id: `matched_vessels_${chosenDate}`,
            text: `${shipDetected} ${areDetectionsCorrelated ? 'verified AIS' : 'ships detected'}`,
            tooltip: 'All detections that has been matched with an AIS transciever',
            active: Boolean(shipDetected),
            icon: <CircleDot {...legendColor.matchedVessels} />,
            currMap: currMap,
            visibleLayers: visibleLayers,
            setVisibleLayers: setVisibleLayers,
        },
        {
            id: `dark_vessels_${chosenDate}`,
            text: areDetectionsCorrelated ? `${darkShipsNumber} dark vessels` : 'AIS correlation not run',
            tooltip: 'All detections that could not be matched to a known vessel',
            active: Boolean(darkShipsNumber),
            icon: <CircleDot {...legendColor.darkShips} />,
            currMap: currMap,
            visibleLayers: visibleLayers,
            setVisibleLayers: setVisibleLayers,
        },
        {
            id: `undetected_ais_${chosenDate}`,
            text: `${unCorrelatedAisNumber} uncorrelated AIS`,
            tooltip: 'All AIS transcievers that could not be matched to a detection',
            active: Boolean(unCorrelatedAisNumber),
            icon: <CircleDot {...legendColor.undetectedAis} size={8} />,
            currMap: currMap,
            visibleLayers: visibleLayers,
            setVisibleLayers: setVisibleLayers,
        },
        {
            text: 'Incorrect AIS (coming soon)',
            active: false,
            icon: '/images/icons/disabled/square.svg',
            currMap: currMap,
            visibleLayers: visibleLayers,
            setVisibleLayers: setVisibleLayers,
        },
        {
            text: 'Transshipments (concept stage)',
            active: false,
            icon: '/images/icons/disabled/square.svg',
            currMap: currMap,
            visibleLayers: visibleLayers,
            setVisibleLayers: setVisibleLayers,
        },
    ];
    const params = useParams();

    const date = availableDates[index];

    return (
        <RoundedWrapper background="#0061A6">
            {availableDates === undefined || availableDates.length === 0 ? (
                <Spinner />
            ) : (
                <>
                    <DetectionsWrapper>
                        <ColumnWrapper id="current_date">
                            <span>{chosenDate}</span>
                        </ColumnWrapper>
                        <ArrowWrapper id="date_navigator">
                            <Arrow disabled={index === 0} onClick={() => prevLayer()}>
                                <img src="/images/icons/ArrowLeft.svg" alt="Previous date" />
                            </Arrow>
                            <Arrow disabled={lastItem} onClick={() => nextLayer()}>
                                <img src="/images/icons/ArrowRight.svg" alt="Next date" />
                            </Arrow>
                        </ArrowWrapper>
                    </DetectionsWrapper>
                    <Border id="legend">
                        <List>
                            {legends.map((legend, i) => (
                                <Legend key={`legend_${i}`} {...legend} />
                            ))}
                        </List>
                    </Border>
                    <Secondary
                        onClick={() => {
                            setVisibleMeta(!visibleMetaData);
                            setProcessingStatus((showProcessingStatus) => !showProcessingStatus);
                        }}
                        border
                    >
                        <span>View all metadata</span>
                    </Secondary>
                    {visibleMetaData && (
                        <Border style={{ background: '#002540' }}>
                            <List>
                                <LiElement>Number of scenes: {metadata && metadata.nr_of_scenes}</LiElement>
                                <LiElement>
                                    Number of scenes processed: {metadata && metadata.nr_of_scenes_processed}
                                </LiElement>
                                <LiElement>
                                    Number of satellite passes in timeframe:{' '}
                                    {metadata && metadata.nr_of_satellite_passes_crossing_aoi_in_timeframe}
                                </LiElement>
                                <LiElement>
                                    Mean cloud coverage: {metadata && metadata.mean_cloud_coverage_percent} %
                                </LiElement>
                                <LiElement>
                                    Total of image area processed:{' '}
                                    {metadata && metadata.total_of_image_area_processed_km2} km<sup>2</sup>
                                </LiElement>
                            </List>
                        </Border>
                    )}
                    <Secondary
                        id="download_csv"
                        onClick={async () => {
                            setDownloadingCsv(true);
                            let name = `Vake Ship Detection Report ${reportName} ${date.toISOString().split('T')[0]}`;
                            await downloadCsv(name, params, date);
                            setDownloadingCsv(false);
                        }}
                    >
                        <span>Download full dataset as CSV-file</span>
                        {downloadingCsv && <Spinner />}
                    </Secondary>
                </>
            )}
        </RoundedWrapper>
    );
};

const Legend = (props: ILegend) => {
    const { id, text, tooltip, active, icon } = props;

    return (
        <>
            <LiElement id={id} active={active} visible={true}>
                {typeof icon === 'string' ? <img src={icon} alt="" /> : icon}
                <span id={`test_${id}`} data-tooltip-content={tooltip}>
                    {text}
                </span>
                <Tooltip anchorId={`test_${id}`} place="right" />
            </LiElement>
        </>
    );
};
